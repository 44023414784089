
.login-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
    /*background-image: url("../../assets/img/image/login-1.png");*/
    background-repeat: no-repeat;
    background-size: contain;
    overflow: auto;
}
.header {
    padding: 20px;
}
.content {
    width: 1000px;
    height: 540px;
    background-color: #fff;
    box-shadow: 1px -1px 15px -1px #ccc;
}
.left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 100%;
    float: left;
    background-color: #fff;
    border-radius: 0 4px 4px 0;
}
.right {
    width: 60%;
    height: 100%;
    float: right;
    background-image: url("../../assets/img/image/student-right.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 4px 0 0 4px;
}
.login-form {
    width:300px;
    height: 400px;
    border-radius: 0 4px 4px 0;
    margin: 60px auto 0;

    .title {
        margin-bottom: 22px;
        text-align: left;
        h3{
            margin: 10px 0;
            font-size: 24px;
            font-family: unset
        }
        .welcome{
            font-size: 22px;
            color: #969696;
        }
        .welcome-title{
            font-size: 19px;
            color: #1669FF;
        }
    }
    ::v-deep.el-input__inner{
        width: 248px;
        height: 38px;
        background: rgba(247,247,247,1);
        border-radius: 19px;
    }
    ::v-deep.el-form-item{
        margin-bottom: 30px;
    }
    ::v-deep.el-form-item__error{
        left: 12px;
    }
    .student-btn{
        width:100px;
        height:35px;
        background:linear-gradient(270deg,rgba(9,92,255,1) 0%,rgba(104,169,253,1) 100%);
        border-radius:18px;
        color: #fff;
        border: none;
    }
    .student-btn:hover{
        background:linear-gradient(320deg,rgba(9,92,255,1) 0%,rgba(104,169,253,1) 100%);
    }
}

